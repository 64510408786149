<template>
    <div class="high-search-page">
        <!-- <com-head></com-head>
        <head-search></head-search> -->
        <div class="back_box" @click="$router.back(-1)">
            <<&nbsp;返回上一页</div> <div class="cont">
                <el-breadcrumb class="braed" separator-class="el-icon-d-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>高级检索</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="content">
                    <el-form label-position="right" label-width="88px" :model="formLabelAlign" ref="foembox">
                        <el-form-item label="文章标题">
                            <el-input v-model="formLabelAlign.name" placeholder="请输入标题"></el-input>
                        </el-form-item>
                        <el-form-item label="关键词">
                            <el-input v-model="formLabelAlign.keyword" placeholder="请输入关键词"></el-input>
                        </el-form-item>
                        <el-form-item label="不包含内容">
                            <el-input v-model="formLabelAlign.noin" placeholder="输入后剔除标题中包含该词语"></el-input>
                        </el-form-item>
                        <el-form-item label="作者">
                            <el-input v-model="formLabelAlign.author" placeholder="请输入作者姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="所属集刊" style="position: relative;">
                            <el-input v-model="formLabelAlign.collection" placeholder="请输入所属集刊"
                                @input="getColllect(formLabelAlign.collection)"></el-input>
                            <div class="suggestList" v-show="showSuggest">
                                <ul>
                                    <li v-for="item in restaurants" @click="changeCollect(item.name,item.id)">
                                        {{item.name}}</li>
                                </ul>
                            </div>
                        </el-form-item>
                        <el-form-item label="在线时间">
                            <!-- <el-date-picker v-model="formLabelAlign.date1" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-DD">
                            </el-date-picker> -->
                            <el-date-picker
                                v-model="formLabelAlign.date1"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-DD"
                            >
                            </el-date-picker>
                        </el-form-item>

                        <!-- <el-date-picker
                                v-model="value1"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-DD">
                            </el-date-picker> -->


                        <!-- <el-form-item label="">
                            <el-checkbox-group v-model="formLabelAlign.type">
                                <el-checkbox label="集刊数据库" name="type"></el-checkbox>
                                <el-checkbox label="文集数据库" name="type"></el-checkbox>
                                <el-checkbox label="预印本数据库" name="type"></el-checkbox>
                                <el-checkbox label="OA数据库" name="type"></el-checkbox>
                            </el-checkbox-group>
                        </el-form-item> -->


                        <el-form-item>
                            <el-button class="red" type="primary" @click="onSubmit">立即检索</el-button>
                            <el-button @click="reset">重置条件</el-button>
                        </el-form-item>
                    </el-form>
                </div>
        </div>
    </div>
</template>

<script>
// import comHead from './head'
// import headSearch from './headSearch'
export default {
    name: 'highSearch',
    data() {
        return {
            formLabelAlign: {
                name: '',
                keyword: '',
                noin: '',
                author: '',
                date1: [],
                date2: '',
                type: ['集刊数据库', '文集数据库', '预印本数据库', 'OA数据库'],
                range: [],
                collection: ''
            },
            value1: '',
            collectionId: '',
            showSuggest: false,
            restaurants: []
        }
    },
    // components:{comHead,headSearch},
    methods: {
        getColllect(queryString) {
            if (queryString) {
                this.showSuggest = true
                var _this = this
                _this.$http
                    .get('/admin/api/cp/list', {
                        params: {
                            name: queryString
                        }
                    })
                    .then(res => {
                        _this.restaurants = res.data.data.rows
                    })
            }
        },
        // switchDate (val) {
        //     console.log('val', val)
        //     console.log('value', this.value1)
        // },
        changeCollect(name, id) {
            this.formLabelAlign.collection = name
            this.collectionId = id
            this.showSuggest = false
        },
        onSubmit() {
            var _this = this

            if (_this.restaurants.length == 1 && _this.collectionId == '') {
                _this.collectionId = _this.restaurants[0].id
            }
            var startDate = ''
            var endDate = ''
            // console.log('this.formLabelAlign.date1', this.formLabelAlign.date1)
            if (this.formLabelAlign.date1[0]) {
                startDate = this.formLabelAlign.date1[0]
                endDate = this.formLabelAlign.date1[1]
            }
            var data = {
                titleKeyword: this.formLabelAlign.name,
                keyWordCnList: this.formLabelAlign.keyword,
                excludeTitle: this.formLabelAlign.noin,
                authorKeyWord: this.formLabelAlign.author,
                collectedPapersId: this.collectionId,
                publicationStartDate: startDate,
                publicationEndDate: endDate
            }

            // authorKeyWord: "高作者"
            // collectedPapersId: ""
            // excludeTitle: "高不包含"
            // keyWordCnList: "高关键词"
            // publicationEndDate: "2021-01-01"
            // publicationStartDate: "2018-12-00"
            // titleKeyword: "高标题"

            localStorage.setItem('search', JSON.stringify(data))
            // this.$router.push({ path: '/search/s', query: { type: 'high' } })
            this.$router.push({path: '/high/h', query:{pN: encodeURI('高级检索')}})
        },
        reset() {
            this.formLabelAlign.name = ''
            this.formLabelAlign.keyword = ''
            this.formLabelAlign.noin = ''
            this.formLabelAlign.author = ''
            this.formLabelAlign.range = ''
            this.formLabelAlign.type = []
            this.formLabelAlign.collection = ''
            this.formLabelAlign.date1 = []
            this.collectionId = ''
            this.showSuggest = false
            this.restaurants = []
        }
    }
}
</script>

<style lang="scss" scoped>
.high-search-page {
}
.back_box {
    width: 1200px;
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
}
.cont {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 50px;
}
.braed {
    margin: 15px 0;
}
.content {
    width: 700px;
    padding-left: 50px;
}
.line {
    text-align: center;
}
.red.el-button {
    background: #BD1A2D;
    border-color: #BD1A2D;
}
.suggestList {
    position: absolute;
    top: 40px;
    left: 0;
    padding: 3px 5px;
    background: #fff;
    width: 200px;
    max-height: 220px;
    overflow: hidden;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    z-index: 10;
}
.suggestList li {
    line-height: 30px;
    color: #666;
    cursor: pointer;
}
.suggestList li:hover,
.suggestList li.on {
    background: #BD1A2D;
    color: #fff;
}
>>> .el-range-editor.is-active,
.el-range-editor.is-active:hover {
    border-color: #4db6be;
}
>>> .el-date-table td.end-date span,
>>> .el-date-table td.start-date span {
    background-color: #4db6be;
}
>>> .el-checkbox__input.is-checked .el-checkbox__inner,
>>> .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #4db6be;
    border-color: #4db6be;
}
>>> .el-checkbox__input.is-checked + .el-checkbox__label,
>>> .el-date-table td.today span {
    color: #4db6be;
}
</style>